import React, { useState, useCallback } from 'react';
import { Box, Button, IconButton, Drawer, List, ListItem, Typography, Menu, MenuItem } from '@mui/joy';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({ mapping: null, sku: null, stn: null,warehouse : null });
  const [submenuOpen, setSubmenuOpen] = useState(null);

  const navigate = useNavigate();

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(prev => !prev);
  }, []);

  const handleMenuOpen = useCallback((type) => (event) => {
    setAnchorEl(prev => ({ ...prev, [type]: event.currentTarget }));
    setSubmenuOpen(null); // Ensure submenu is closed
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl({ mapping: null, sku: null, stn: null });
    setSubmenuOpen(null);
  }, []);

  const handleSubmenuToggle = useCallback((menu) => {
    setSubmenuOpen(prev => (prev === menu ? null : menu));
  }, []);

  const handleNavigation = useCallback((path) => {
    navigate(path);
    handleMenuClose();
  }, [navigate, handleMenuClose]);

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        backgroundColor: '#653d7c',
        color: '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1, color: '#fff' }}>
        STN Manager
      </Typography>

      {!isMobile && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="plain" sx={{ color: '#fff' }} onClick={() => navigate('/home')}>Home</Button>
          <Button
            variant="plain"
            sx={{ color: '#fff',font:'Inter' }}
            onClick={handleMenuOpen('stn')}
            endIcon={<MoreVertIcon />}
          >
            STN <ArrowDropDownIcon sx={{ fontSize: 16 }} />
          </Button>
          <Menu
            anchorEl={anchorEl.stn}
            open={Boolean(anchorEl.stn)}
            onClose={handleMenuClose}
            sx={{ mt: 1, '& .MuiPaper-root': { backgroundColor: '#333', color: '#fff', margin: '10' } }}
          >
            <MenuItem onClick={() => handleNavigation('/create-stn')}>Create New STN</MenuItem>
            <MenuItem onClick={() => handleNavigation('/list')}>View All STN</MenuItem>
          </Menu>
          <Button
            variant="plain"
            sx={{ color: '#fff' }}
            onClick={handleMenuOpen('warehouse')}
            endIcon={<MoreVertIcon />}
          >
            Warehouse <ArrowDropDownIcon sx={{ fontSize: 16 }} />
          </Button>
          <Menu
            anchorEl={anchorEl.warehouse}
            open={Boolean(anchorEl.warehouse)}
            onClose={handleMenuClose}
            sx={{ mt: 1, '& .MuiPaper-root': { backgroundColor: '#333', color: '#fff', margin: '10' } }}
          >
            <MenuItem onClick={() => handleNavigation('/create-warehouse')}>Create New Warehouse</MenuItem>
            <MenuItem onClick={() => handleNavigation('/warehouse-list')}>View All Warehouse</MenuItem>
          </Menu>
          <Button
            variant="plain"
            sx={{ color: '#fff' }}
            onClick={handleMenuOpen('sku')}
            endIcon={<MoreVertIcon />}
          >
            SKU <ArrowDropDownIcon sx={{ fontSize: 16 }} />
          </Button>
          <Menu
            anchorEl={anchorEl.sku}
            open={Boolean(anchorEl.sku)}
            onClose={handleMenuClose}
            sx={{ mt: 1, '& .MuiPaper-root': { backgroundColor: '#333', color: '#fff', margin: '10' } }}
          >
            <MenuItem onClick={() => handleNavigation('/sku-creation')}>Create New SKU</MenuItem>
            <MenuItem onClick={() => handleNavigation('/sku-list')}>View All SKU</MenuItem>
          </Menu>
          <Button
            variant="plain"
            sx={{ color: '#fff' }}
            onClick={handleMenuOpen('mapping')}
            endIcon={<MoreVertIcon />}
          >
            Mapping <ArrowDropDownIcon sx={{ fontSize: 16 }} />
          </Button>
          <Menu
            anchorEl={anchorEl.mapping}
            open={Boolean(anchorEl.mapping)}
            onClose={handleMenuClose}
            sx={{ mt: 1, '& .MuiPaper-root': { backgroundColor: '#333', color: '#fff', margin: '10' } }}
          >
            <MenuItem onClick={() => handleNavigation('/create-mapping')}>Create New Mapping</MenuItem>
            <MenuItem onClick={() => handleNavigation('/mapping-list')}>View All Mapping</MenuItem>
          </Menu>
        </Box>
      )}

      {isMobile && (
        <>
          <IconButton color="neutral" onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: 'white' }} />
          </IconButton>
          <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
            <List sx={{ backgroundColor: '#653d7c', color: '#000' }}>
              <ListItem button onClick={() => navigate('/')} sx={{color : 'white'}}>Home</ListItem>
              <ListItem button onClick={() => handleSubmenuToggle('stn')} sx={{color : 'white'}}>
                STN <ArrowRightIcon sx={{ fontSize: 16, ml: 1 }} />
              </ListItem>
              {submenuOpen === 'stn' && (
                <List sx={{ pl: 4 }}>
                  <ListItem button onClick={() => handleNavigation('/create-stn')} sx={{color : 'white'}}>Create New STN</ListItem>
                  <ListItem button onClick={() => handleNavigation('/list')} sx={{color : 'white'}}>View All STN</ListItem>
                </List>
              )}
              <ListItem button onClick={() => navigate('/')} sx={{color : 'white'}}>Home</ListItem>
              <ListItem button onClick={() => handleSubmenuToggle('warehouse')} sx={{color : 'white'}}>
                Warehouse <ArrowRightIcon sx={{ fontSize: 16, ml: 1 }} />
              </ListItem>
              {submenuOpen === 'warehouse' && (
                <List sx={{ pl: 4 }}>
                  <ListItem button onClick={() => handleNavigation('/create-warehouse')} sx={{color : 'white'}}>Create New Warehouse</ListItem>
                  <ListItem button onClick={() => handleNavigation('/warehouse-list')} sx={{color : 'white'}}>View All Warehouse</ListItem>
                </List>
              )}
              <ListItem button onClick={() => handleSubmenuToggle('sku')} sx={{color : 'white'}}>
                SKU <ArrowRightIcon sx={{ fontSize: 16, ml: 1 }} />
              </ListItem>
              {submenuOpen === 'sku' && (
                <List sx={{ pl: 4 }}>
                  <ListItem button onClick={() => handleNavigation('/sku-creation')} sx={{color : 'white'}}>Create New SKU</ListItem>
                  <ListItem button onClick={() => handleNavigation('/sku-list')} sx={{color : 'white'}}>View All SKU</ListItem>
                </List>
              )}
              <ListItem button onClick={() => handleSubmenuToggle('mapping')} sx={{color : 'white'}}>
                Mapping <ArrowRightIcon sx={{ fontSize: 16, ml: 1 }} />
              </ListItem>
              {submenuOpen === 'mapping' && (
                <List sx={{ pl: 4 }}>
                  <ListItem button onClick={() => handleNavigation('/create-mapping')} sx={{color : 'white'}}>Create New Mapping</ListItem>
                  <ListItem button onClick={() => handleNavigation('/mapping-list')} sx={{color : 'white'}}>View All Mapping</ListItem>
                </List>
              )}
            </List>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export default Navbar;
