import React,{useState,useEffect} from 'react';
import axios from "axios";
import { Typography } from '@mui/joy';

const CountComponent = ({ text,apiurl }) => {

  const [count, setCount] = useState(0);

  useEffect(() => {
    console.log(apiurl)
    // Fetch data from the API
    axios
      .get("https://stnapiv2.stcsolutions.shop/api/stocktransfer/"+apiurl)
      .then((response) => {
        const data = response.data;
        console.log(response)

        setCount(data);
      })
      .catch((error) => {
        console.error("Error fetching stock transfer data:", error);
      });
  }, []);


  return (
    <>
    <div style={{
      backgroundColor: '#f0f4f8', // Light background shade
      padding: '10px 20px', // Padding inside the box
      borderRadius: '8px', // Rounded corners
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Light shadow for depth
      maxWidth: '300px', // Optional: limit width
      margin: '10px auto', // Centered container
    }}>
    <Typography variant="h6">{text}<strong style={{fontSize : '25px'}}>{count}</strong></Typography>
    </div>
    </>
  );
};

export default CountComponent;
