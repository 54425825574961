import React, { useState } from 'react';
import { FormControl, Select, Input, FormLabel,Option } from '@mui/joy';

const Dropdown = ({ label, value, options, onChange,errorText,keyIdentifier,valueIdentifier,displayTextIdentifier }) => {
  

  
  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel> 
      <Select value={value} onChange={onChange}>
      
        {options.map((option, index) => (
          <Option key={option[keyIdentifier]} value={option[valueIdentifier]}>{option[displayTextIdentifier]}</Option>
        ))}
      </Select>
      <FormLabel sx={{color:'orangered'}}>{errorText}</FormLabel>
    </FormControl>
  );
};

export default Dropdown;
