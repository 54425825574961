import React, { useEffect, useState,useRef,useCallback } from 'react';
import './App.css';
import axios from 'axios';
import { Grid, Button } from '@mui/joy';
import Dropdown from './components/Dropdown';
import DatePicker from './components/DatePicker';
import DataGrid from './components/DataGrid';
import CustomModal from './components/Modal';
import TextInput from './components/TextInput';
import Notify from './components/ToastNotification';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Sample data for dropdowns
//let companies = ['Company A', 'Company B', 'Company C'];
let vendors = ['Vendor X', 'Vendor Y', 'Vendor Z'];
let skus = ['SKU1', 'SKU2', 'SKU3'];

const columnDefs = [
  { headerName: "Sr No", field: "srNo", checkboxSelection: true },
  { headerName: "ID", field: "id", hide : true },
  { headerName: "ASIN/FSN", field: "asin" },
  { headerName: "FNSKU/Listing ID", field: "fnsku" },
  { headerName: "SKU/Model", field: "sku" },
  { headerName: "Quantity", field: "quantity" },
  { headerName: "MRP", field: "mrp" },
  { headerName: "Amount", field: "amount" }
];


let initialRowData = [];

function PageComponent() {
    const navigate = useNavigate();

  const getData  = async (apiURL) => {
             try {
                 const response = await axios.get(apiURL);
                 
                 return response.data;
             } catch (error) {
                 return error.message;
             } finally {
                 
             }
         };


         const postData  = async (apiURL,data) => {
          try {
              const response = await axios.post(apiURL,data);
              toast.success("STN created successfully")
              navigate('/list')
          } catch (error) {
              return error.message;
          } finally {
              
          }
      };




  const [company, setCompany] = useState('');
  const [companies, setCompanyList] = useState([]);
  const [vendor, setVendor] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [appointmentDate, setAppointmentDate] = useState(new Date().toISOString().split('T')[0]);
  const [rowData, setRowData] = useState(initialRowData);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState();
  const [skuQuantity, seSkuQuantity] = useState('');
  const [appointmentID, setAppointmentID] = useState('');
  const [shipmentID, setShipmentID] = useState('');
  const [boxCount, setBoxCount] = useState('');
  const [warehouses, setWarehouses] = useState([]);
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [driverName, setDriverName] = useState('');

  const [companyError, setCompanyError] = useState('');
  const [vendorError, setVendorError] = useState('');
  const [appointmentIDError, setAppointmentIDError] = useState('');
  const [shipmentIDError, setShipmentIDError] = useState('');
  const [boxError, setBoxError] = useState('');
  const [skuError, setSkuError] = useState('');
  const [vehicleNumberError, setVehicleNumberError] = useState('');
  const [driverNameError, setDriverNameError] = useState('');
  const gridRef = useRef();


  useEffect(() => {
    const fetchData = async () => {
      const data = await getData("https://stnapiv2.stcsolutions.shop/api/Company");
      
      setCompanyList(data);
      vendors = [];
      data.filter(c => c.type != 3).forEach(c => {
      let gsts = c['warehouses']
      vendors.push(...gsts)
      })
    };

    fetchData();
  }, []); 

  useEffect(() => {
    const fetchSKUMapping = async () => {
      const data = await getData("https://stnapiv2.stcsolutions.shop/api/AmazonSKUMapping");
      skus = [];
      skus = data
    };

    fetchSKUMapping();
  }, []); 

  

  // Handle modal open and close
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // Handle modal actions

  useEffect(() => {
    if (company) {
      //const filteredOptions = vendors.filter(option => option.gstNumber === companies.find(c => c.id == company).gstNumber);
      //setWarehouses(filteredOptions);
      setWarehouses(vendors);
    } else {
      setWarehouses([]);
    }
  }, [company]);

  const handleAccept = (selectedOption, inputValue) => {
    seSkuQuantity(selectedOption);
    seSkuQuantity(inputValue);
    const exisitingSKUCount = initialRowData.length;
    initialRowData.push({ 
      srNo: (exisitingSKUCount + 1),
      id: selectedOption.id, 
      asin: selectedOption.asin, 
      fnsku: selectedOption.fnsku, 
      sku: selectedOption.skuName, 
      quantity: inputValue, 
      mrp: selectedOption.sku.mrp, 
      amount: (inputValue * selectedOption.sku.mrp) 
    })
    setRowData([...initialRowData])
    closeModal();
  };

 
  const handleCompanyChange =(event, newValue) => setCompany(newValue);
  const handleVendorChange = (event, value) => setVendor(value);
  const handleDateChange = (event, value) => setDate(event?.target.value);
  const handleAppointmentDateChange = (event,value) => setAppointmentDate(event?.target.value);
  const handleBoxChange = (event, newValue) => {console.log("set boix cva",event?.target.value);setBoxCount(event?.target.value)};
  const handleAppointmentIDChange = (event, newValue) => setAppointmentID(event?.target.value);
  const handleShipmentIDChange = (event, newValue) => {
    setShipmentID(event?.target.value);
  }
  const handleVehicleNumberChange = (event, value) => setVehicleNumber(event?.target.value);
  const handleDriverNameChange = (event, value) => setDriverName(event?.target.value);

  // Calculate total amount
  const totalAmount = rowData.reduce((acc, row) => acc + row.amount, 0);
  const totalQuantity = rowData.reduce((acc, row) => acc + parseInt(row.quantity), 0);

  function clearErrors(){
    setSkuError('')
    setCompanyError('')
    setVendorError('')
    setBoxError('')
    setShipmentIDError('')
    setAppointmentIDError('')
    setVehicleNumberError('')
    setDriverNameError('')
  }

  function hasError(){

    if(skuError.length > 0 || companyError.length > 0 || 
      vendorError.length > 0 || boxError.length > 0 || 
      shipmentIDError.length > 0 || appointmentIDError.length > 0 || 
      vehicleNumberError.length > 0 || driverNameError.length > 0){
        return true;
      }
      else{
        return false;
      }
  }

  function validate() {
    clearErrors()
    if (rowData.length == 0) {
      setSkuError("Please Add SKU")
    }
    if (company == null) {
      setCompanyError("Please select company")
    }
    if (vendor == null) {
      setVendorError("Please select Vendor")
    }
    if (boxCount == null || boxCount == "") {
      setBoxError("Please specify box count")
    }
    if (shipmentID == null || shipmentID == "") {
      setShipmentIDError("Please specify Shipment ID")
    }
    if (appointmentID == null || appointmentID == "") {
      setAppointmentIDError("Please specify Appointment ID")
    }
    if (vehicleNumber == null || vehicleNumber == "") {
      setVehicleNumberError("Please specify Vehicle Number")
    }
    if (driverName == null || driverName == "") {
      setDriverNameError("Please specify Driver name")
    }

    const fetchExisitngTransferWithSameShipmentID = async () => {
      const data = await getData("https://stnapiv2.stcsolutions.shop/api/Stocktransfer/CheckShipment/" + shipmentID);
      if(data != null || data != undefined){
        if(data.shipmentID == shipmentID){
          setShipmentIDError("STN with shipment id already exists")          
        }
      }
    };

    fetchExisitngTransferWithSameShipmentID();

    if(hasError() == true){
      toast.error("Please correct errors",{
        "position" : "top-center"
      });
      return
    }
    let stockJSON = [];
    rowData.forEach(row => {
      const json = {
        amazonskumappingid : row.id,
        totalamount : row.amount,
        totalquantity : row.quantity,
      }
      stockJSON.push(json)
    })
    const stockTransfer = {
      transferdate : date,
      vehiclenumber : vehicleNumber,
      drivername : driverName,
      totalquantity : totalQuantity,
      totalvaluewithgst : totalAmount,
      appointmentid : appointmentID,
      shipmentid : shipmentID,
      appointmentdate : appointmentDate,
      numberofboxes : boxCount,
      companygstid : vendor,
      stockmovementDtos : stockJSON
    }
    console.log(stockTransfer)
    postData("https://stnapiv2.stcsolutions.shop/api/StockTransfer",stockTransfer)
    
  }

  const onRemoveSelected = useCallback(() => {
    const selectedData = gridRef.current.api.getSelectedRows();
    const newData = initialRowData.filter(item => !selectedData.includes(item));
    setRowData([...newData])
    initialRowData = newData;
    const res = gridRef.current.api.applyTransaction({ remove: selectedData });
    
  }, []);

  return (
    <div>
    <Notify />
      <Grid container spacing={2} p={4} sx={{width : '100%'}}>
        {/* Row 1 */}
        <Grid item xs={12} sm={4}>
          <Dropdown
            label="Company (Bill To)"
            value={company}
            options={companies}
            keyIdentifier="id"
            valueIdentifier="id"
            displayTextIdentifier="placeCode"
            errorText={companyError}
            onChange={handleCompanyChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            label="Vendor/Warehouse (Ship To)"
            value={vendor}
            options={warehouses}
            keyIdentifier="id"
            valueIdentifier="id"
            displayTextIdentifier="placeCode"
            errorText={vendorError}
            onChange={handleVendorChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={date}
            dateLabel="Transfer Date"
            onChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Appointment ID"
            placeholder="Enter Appointment ID"
            errorText={appointmentIDError}
            value={appointmentID}
            onChange={handleAppointmentIDChange}
          />

        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Shipment ID"
            placeholder="Enter Shipment ID"
            value={shipmentID}
            errorText={shipmentIDError}
            onChange={handleShipmentIDChange}
          />

        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={appointmentDate}
            dateLabel="Appointment Date"
            onChange={handleAppointmentDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput
            label="Box"
            type="number"
            placeholder="Enter box count"
            value={boxCount}
            errorText={boxError}
            onChange={handleBoxChange}
          />

        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInput
            label="Vehicle Number"
            placeholder="Format MH04AA1111"
            value={vehicleNumber}
            errorText={vehicleNumberError}
            onChange={handleVehicleNumberChange}
          />

        </Grid>
        <Grid item xs={12} sm={3}>
          <TextInput
            label="Driver name"
            placeholder="Enter Driver name"
            value={driverName}
            errorText={driverNameError}
            onChange={handleDriverNameChange}
          />

        </Grid>

        <Grid item xs={12} sm={2}>
          <TextInput
            label="Total Unit"
            type="number"
            placeholder="Enter Total Unit"
            value={totalQuantity}
            disabled="True"
          />

        </Grid>
        <Grid item xs={12} sm={2}>
          <TextInput
            label="Total Amount"
            type="number"
            placeholder="Enter Total Amount"
            value={totalAmount}
            disabled="True"
          />

        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="outlined" color="primary" onClick={openModal}>
            Add SKU
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button variant="outlined" color="primary" onClick={onRemoveSelected}>
            Remove Selected
          </Button>
        </Grid>
        {/* Row 2 */}
        <Grid item xs={12}>


          <DataGrid
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            height={'430px'}
          />




        </Grid>


        <Grid item xs={12} sm={6}>
          <Button variant="outlined" color="primary" onClick={validate}>
            Create Stock Transfer Note
          </Button>
        </Grid>

        <CustomModal
          open={modalOpen}
          onClose={closeModal}
          title="Select your SKU"
          skuOptions={skus}
          selectedSKU={selectedSKU}
          onAccept={handleAccept}
          lstAddedSKU={rowData}
        />
      </Grid>
    </div>
  );
}




export default PageComponent;
