import React,{useState,useEffect,useRef} from 'react';
import { Grid, Button } from '@mui/joy';
import DataGrid from './components/DataGrid';
import axios from 'axios';
import Box from '@mui/material/Box';
import ButtonCellRenderer from './cellRenderer/Button';
import LinkCellRenderer from './cellRenderer/LinkCellRenderer';
import DeleteButtonCellRenderer from './cellRenderer/DeleteButton';
import ButtonTextcellEditor from './cellRenderer/ButtonTextcellEditor';
import PopcellEditorGrid from './cellRenderer/PopcellEditorGrid';
import PdfGenerator from './cellRenderer/PDFButton';

function ListStockTransfer() {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();
    const columnDefs = [
        
      {
        headerName: "Delete STN",
        cellRenderer: 'downloadButtonCellRenderer',
        cellRendererParams : {
          url : "https://stnapiv2.stcsolutions.shop/api/stocktransfer/delete/"
        },
        width: 150
      },
        {
          headerName: "Eway Bill JSON",
          cellRenderer: 'buttonCellRenderer',
          cellRendererParams : {
            url : "https://stnapiv2.stcsolutions.shop/api/stocktransfer/GetEwaybilljson/",
            buttonText : "Download",
            fileExtension : "json"
          },
          width: 150
        },
        {
          headerName: "STN",
          cellRenderer: 'pdfcellrenderor',
          cellRendererParams : {
            url : "https://stnapiv2.stcsolutions.shop/api/stocktransfer/GetPDF/",
            buttonText : "Download",
            fileExtension : "pdf"
          },
          width: 80
        },
        // {
        //   headerName: "STN",
        //   cellRenderer: 'buttonCellRenderer',
        //   cellRendererParams : {
        //     url : "https://stnapiv2.stcsolutions.shop/api/stocktransfer/GetPDF/",
        //     buttonText : "Download",
        //     fileExtension : "pdf"
        //   },
        //   width: 80
        // },
        {
          headerName: "Tally STN",
          cellRenderer: 'buttonCellRenderer',
          cellRendererParams : {
            url : "https://stnapiv2.stcsolutions.shop/api/stocktransfer/GetMappingSTN/",
            buttonText : "Download",
            fileExtension : "xlsx"
          },
          width: 80
        },
        { headerName: "Shipment ID", field: "shipmentID",cellRenderer: 'linkCellRenderer' },
        {
          headerName: "Eway Bill Number", field: "ewayBillNumber",editable : true,
          cellEditor: 'buttonTextcellEditor', cellEditorPopup: true,
          cellEditorParams : {
            url : "https://stnapiv2.stcsolutions.shop/api/stocktransfer/ewaybillno/"
          }
        },
        { headerName: "ID", field: "id", hide : true },
        { headerName: "Transfer Date", field: "transferDate" },
        { headerName: "Vehicle Number", field: "vehicleNumber" },
        { headerName: "Driver Name", field: "driverName" },
        { headerName: "Total Quantity", field: "totalQuantity" },
        { headerName: "Total Value With GST", field: "totalValueWithGST" },
        { headerName: "Appointment ID", field: "appointmentID" },
        
        { headerName: "Appointment Date", field: "appointmentDate" },
        { headerName: "Number Of Boxes", field: "numberOfBoxes" },
        { headerName: "companyGSTId", field: "companyGSTId" }
      ];

     
      const getData  = async (apiURL) => {
        try {
            const response = await axios.get(apiURL);
            
            return response.data;
        } catch (error) {
            return error.message;
        } finally {
            
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          const data = await getData("https://stnapiv2.stcsolutions.shop/api/StockTransfer");
          setRowData(data);
        };
    
        fetchData();
      }, []); 
  return (
    <Box sx={{ margin: '16px' }}>
    <Grid item xs={12}>


          <DataGrid
            rowData={rowData}
            columnDefs={columnDefs}
            frameworkComponent={{
                    buttonCellRenderer: ButtonCellRenderer,
                    linkCellRenderer : LinkCellRenderer,
                    downloadButtonCellRenderer : DeleteButtonCellRenderer,
                    buttonTextcellEditor : PopcellEditorGrid,
                    pdfcellrenderor : PdfGenerator
                }}
                height={'calc(100vh - 160px)'}
              
          />




        </Grid>
        </Box>
  );
  
  
}




export default ListStockTransfer;
