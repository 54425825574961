import React, {  useCallback } from 'react';
import PopupCellEditor from './PopcellEditor';
import axios from 'axios';
import { toast } from 'react-toastify';

const PopcellEditorGrid = (props) => {
  const { api, column, node, value } = props;

  const applyChanges = useCallback(
    (newValue) => {
        if(newValue == ''){
            newValue = '-'
        }

        const handleDelete = async () => {
            try {
                const response = await axios.put(props.url + props.node.data.id + "/" +  newValue,{});
                if(response.data == "Eway bill number updated successfully"){
                  api.getCellEditorInstances()[0].updateValue(newValue)
                  api.stopEditing();
                } 
            } catch (error) {
                console.error('Failed to update STN', error);
            }
        };

        handleDelete();
    },
    [api]
  );

  const getValue = useCallback(() => {
    return value;
  }, [value]);

  const setValue = useCallback((newValue) => {
    api.getCellEditor().setValue(newValue); // Set the new value in the grid
  }, [api]);

  // Indicate that this editor should be shown as a popup
  const isPopup = useCallback(() => true, []);

  return (
    <PopupCellEditor
      value={value}
      api={api}
      column={column}
      node={node}
      applyChanges={applyChanges}
      getValue={getValue}
      setValue={setValue}
      isPopup={isPopup}
    />
  );
};

export default PopcellEditorGrid;
