import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";

const StockTransferChart = () => {
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("https://stnapiv2.stcsolutions.shop/api/stocktransfer/GetSKUWiseTransferCount")
      .then((response) => {
        const data = response.data;

        // Process the data for the chart
        const categories = data.map((item) => item.mappingName); // X-axis labels
        const seriesData = data.map((item) => item.totalSum); // Y-axis values

        setChartData({
          categories,
          series: [
            {
              name: "Total Stock Movements",
              data: seriesData,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error fetching stock transfer data:", error);
      });
  }, []);

  // Chart configuration
  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: { show: false },
      
    },
    xaxis: {
      categories: chartData.categories,
      
      labels: {
        formatter: (val) => (val.length > 15 ? `${val.substring(0, 12)}...` : val),
        rotate : -90
      },
    },
    yaxis: {
      title: {
        text: "Total SKU",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Stock Transfer Summary",
      align: "center",
    },
    grid: {
        padding: {
          bottom: 70, // Increase padding at the bottom
        },
      },
      colors : chartData.categories.map(() => getRandomColor())
  };

  

  return (
    <div>
      {chartData.series.length > 0 ? (
        <Chart options={chartOptions} series={chartData.series} type="bar" height={400} />
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

export default StockTransferChart;
