import React,{useState,useEffect} from 'react'
import Notify from './components/ToastNotification';
import { Grid, Button } from '@mui/joy';
import TextInput from './components/TextInput';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Dropdown from './components/Dropdown'; 

function AmazonSKUMapping() {
    const navigate = useNavigate();


    const [asin, setASIN] = useState('');
    const [fnsku, setFNSKU] = useState('');
    const [asinError, setASINError] = useState('');
    const [fnskuError, setFNSKUError] = useState('');
    const [skuName, setSKUName] = useState('');
    const [skuNameError, setSKUNameError] = useState('');
    const [stcSKU, setSTCSKU] = useState('');
    const [stcSKUError, setSTCSKUError] = useState('');
    const [lstSTCSKU, setlstSTCSKU] = useState([]);

    const handleASINChange = (event, newValue) => setASIN(event?.target.value);
  const handleFNSKUChange = (event, newValue) => {
    setFNSKU(event?.target.value);
  }
  const handleSKUNameChange = (event, newValue) => setSKUName(event?.target.value);
  const handleSTCSKUChange =(event, newValue) => setSTCSKU(newValue);

  const getData  = async (apiURL) => {
    try {
        const response = await axios.get(apiURL);
        
        return response.data;
    } catch (error) {
        return error.message;
    } finally {
        
    }
};



  const postData  = async (apiURL,data) => {
    try {
        const response = await axios.post(apiURL,data);
        console.log(response)
        toast.success("SKU created successfully")
        navigate('/mapping-list')
    } catch (error) {
        return toast.error(error.message);
    } finally {
        
    }
};

useEffect(() => {
  const fetchData = async () => {
    const data = await getData("https://stnapiv2.stcsolutions.shop/api/SKU");
    
    


    const mappingData = await getData("https://stnapiv2.stcsolutions.shop/api/amazonSKUMapping");
    const lstMappedSKU = mappingData;
    const lstNewSKU = []
    data.forEach(element => {
      const hasMapping = lstMappedSKU.some(s => s.skuId == element.id)
      if(!hasMapping){
        lstNewSKU.push(element)
      }
    });
    setlstSTCSKU(lstNewSKU)
  };

  fetchData();
}, []); 



  function validate(){
    setSKUNameError('');
    setASINError('');
    setFNSKUError('');
    console.log(fnsku)
    if(asin == null || asin == undefined || asin == ''){
        setSKUNameError("Please specify asin")
    }
    if(fnsku == null || fnsku == undefined || fnsku == ''){
        setFNSKUError("Please specify fnsku")
    }
    if(skuName == null || skuName == undefined || skuName == ''){
      setSKUNameError("Please specify sku name")
  }
    
    if(asinError.trim().length == 0 && fnskuError.trim().length == 0 && skuNameError.trim().length == 0){
        const save = async () => {
            const data = await postData("https://stnapiv2.stcsolutions.shop/api/amazonSKUMapping",{asin : asin,fnsku : fnsku,skuname : skuName,skuId : stcSKU,sku:lstSTCSKU.find(s => s.name = stcSKU)});
            console.log(data)
          };
      
          save();
    }
  }

    return (
        <div>
    <Notify />
      <Grid container spacing={2} p={4} sx={{width : '100%'}}>
        
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="ASIN / FSN"
            placeholder="Enter ASIN / FSN"
            errorText={asinError}
            value={asin}
            onChange={handleASINChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="FNSKU / Listing ID"
            placeholder="Enter FNSKU / Listing ID"
            errorText={fnskuError}
            value={fnsku}
            onChange={handleFNSKUChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
          <TextInput
            label="SKU / Model Name"
            placeholder="Enter SKU / Model Name"
            errorText={skuNameError}
            value={skuName}
            onChange={handleSKUNameChange}
          />

        </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>

        <Dropdown
            label="SKU"
            value={stcSKU}
            options={lstSTCSKU}
            keyIdentifier="id"
            valueIdentifier="id"
            displayTextIdentifier="name"
            errorText={stcSKUError}
            onChange={handleSTCSKUChange}
          />
          </Grid>
        <Grid item xs={12} mdOffset={4} sm={4}>
        <Button variant="outlined" color="primary" onClick={validate}>
            Create Mapping
          </Button>

        </Grid>
      </Grid>
    </div>
    );
}

export default AmazonSKUMapping;