import React from 'react';
import StockTransferChart from './components/SKUWiseTransfer';
import CountComponent from './components/CountComponent';

const HomeComponent = () => {
  return (
    <>
    <div style={{
        display:'flex'
      }}>
      <CountComponent text={"Total SKU Value : "} apiurl={'GetTotalTransferValue'} />
      <CountComponent text={"Total SKU : "} apiurl={'GetTotalTransfer'} />
      </div>
      <StockTransferChart />
      </>
  );
};

export default HomeComponent;


