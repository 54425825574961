import React, { useState } from 'react';
import { Modal, ModalDialog, ModalClose, Button, Select, Option, FormControl, Input, FormLabel } from '@mui/joy';
import { toast } from 'react-toastify';

const CustomModal = ({ open, onClose, title, skuOptions, selectedSKU, onAccept,lstAddedSKU }) => {

  const [selectedSKUItem, setSelectedSKU] = useState('');
  const [inputQuantityValue, setInputQuantityValue] = useState(0);
  const [filteredOptions,setFilteredOptions] = useState(skuOptions)
  const [serachedText,setSerachedText] = useState('')

  const handleSKUChange = (event,option) => {
    setSelectedSKU(option);
  };
  
  const handleQuantityChange = (event) => {
    setInputQuantityValue(event.target.value);
  };

  function validate(){
    let hasError = false;
    if(inputQuantityValue <= 0 ){
      toast.error("Please specify valid quantity",{
        "position" : "top-center"
      })
      hasError = true;
    }
    if(selectedSKUItem == undefined || selectedSKUItem == null || selectedSKUItem == ''){
      toast.error("Please select sku",{
        "position" : "top-center"
      })
      hasError = true;
    }
    
    const isSKUAlreadyAdded = lstAddedSKU.some(sku => sku.sku == selectedSKUItem.skuName);
    
    if(isSKUAlreadyAdded){
      toast.error("Selected SKU is already added in STN",{
        "position" : "top-center",
      })
      hasError = true;
    }

    return hasError;
  }

  const handleAcceptClick = () => {
    if(validate()){
      return;
    }
    onAccept(selectedSKUItem, inputQuantityValue);
    // Close the modal or perform any other necessary actions
  };

  const handleKeyDown = (event) =>{
    if(event.key == "Enter"){
      event.preventDefault();
      handleAcceptClick();
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
      
      const data = skuOptions.filter(o => o.skuName.toLowerCase().includes(event.target.value.toLowerCase()))
      setFilteredOptions(data)
      setSerachedText(event.target.value);    
   
  }
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <h2>{title}</h2>
        <div style={{ padding: '16px' }}>
          <FormControl fullWidth>
            <FormLabel>SKU</FormLabel>
            <Select
              value={selectedSKU}
              onChange={handleSKUChange}
              placeholder="Select SKU"
            >
            <Input placeholder='Search' value={serachedText} onChange={handleChange} autoFocus/>
              {serachedText.length == 0 ? skuOptions.map((sku, index) => (
                <Option key={index} value={sku}>{sku.skuName}</Option>
              )) : filteredOptions.map((sku, index) => (
                <Option key={index} value={sku}>{sku.skuName}</Option>
              ))}
            </Select>
            <br />
            <FormLabel>Quantity</FormLabel>
            <Input placeholder="Enter Quantity" type="number" value={inputQuantityValue} onChange={handleQuantityChange} onKeyDown={handleKeyDown} />
          </FormControl>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color='danger' onClick={onClose}>Cancel</Button>
          <Button variant="outlined" color='success' onClick={handleAcceptClick}>Add</Button>
        </div>
      </ModalDialog>
    </Modal>
  );
};

export default CustomModal;