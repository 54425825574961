import React, { useState } from 'react';
import { Input, Button, Box } from '@mui/joy';

const PopupCellEditor = ({ value, api, applyChanges, getValue, setValue, isPopup }) => {
  const [inputValue, setInputValue] = useState(getValue() || '');

  const handleSubmit = () => {
    applyChanges(inputValue); // Apply changes to the grid
  };

  const handleCancel = () => {
    api.stopEditing(); // Close the editor
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', background: '#fff', border: '1px solid #ddd' }}>
      <Input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        style={{ marginBottom: '10px' }}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between',float:'right',marginLeft:'auto' }}>
        <Button onClick={handleSubmit} variant='outlined' color='primary' >Update</Button>
      </div>
    </div>
  );
};

export default PopupCellEditor;
