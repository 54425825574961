import React,{useState,useEffect} from 'react';
import { Grid, Button } from '@mui/joy';
import DataGrid from './components/DataGrid';
import axios from 'axios';
import Box from '@mui/material/Box';
import DeleteButtonCellRenderer from './cellRenderer/DeleteButton';

function AmazonSKUMappingList() {

    const columnDefs = [
      {
        headerName: "",
        cellRenderer: 'downloadButtonCellRenderer',
        cellRendererParams : {
          url : "https://stnapiv2.stcsolutions.shop/api/amazonSKUMapping/delete/"
        },
        width: 80
      },

        { headerName: "ASIN", field: "asin",filter:true },
        { headerName: "ID", field: "id", hide : true },
        { headerName: "FNSKU", field: "fnsku",filter:true },
        { headerName: "SKU", field: "skuName",filter:true,width : 450  },
        { headerName: "Self SKU Name", field: "sku.name",filter:true,width : 450  }
      ];

      const [rowData, setRowData] = useState([]);
      const getData  = async (apiURL) => {
        try {
            const response = await axios.get(apiURL);
            
            return response.data;
        } catch (error) {
            return error.message;
        } finally {
            
        }
    };

    useEffect(() => {
        const fetchData = async () => {
          const data = await getData("https://stnapiv2.stcsolutions.shop/api/amazonSKUMapping");
          setRowData(data);
        };
    
        fetchData();
      }, []); 
  return (
    <Box sx={{ margin: '16px' }}>
    <Grid item xs={12} p={4} sx={{width : '100%'}}>


          <DataGrid
            rowData={rowData}
            columnDefs={columnDefs}
                height={'calc(100vh - 180px)'}
                frameworkComponent={{
                    downloadButtonCellRenderer : DeleteButtonCellRenderer
                }}
          />




        </Grid>
        </Box>
  );
  
  
}




export default AmazonSKUMappingList;
